import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

interface modalProps {
    defaultPaymentModalShow:()=> void;
    defaultPaymentModalClose:()=> void;
    modalShow:any;
}
const PaymentRemoval:React.FC<modalProps> = (props) => {

  const { t } = useTranslation("common");
  return (
    <>

      <Modal show={props.modalShow} onHide={props.defaultPaymentModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="bp-defpymnt-title" >
            {t("removeDefaultPayment.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} lg={12} className="bp-defpymnt-content1">{t("removeDefaultPayment.content1")}</Col>
            <Col xs={12} sm={12} lg={12} className="bp-defpymnt-content2">{t("removeDefaultPayment.content2")}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer
        className="bp-defpymnt-footer"
        >
          <Button variant="secondary" onClick={props.defaultPaymentModalClose} className="bp-defpymnt-btn" >
          {t("removeDefaultPayment.ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PaymentRemoval;
