import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
const AppLayout = () => {
  return (
    <div className="d-flex">
      <Sidebar />
      <div>
        <div className="container">
          <Header />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>

  );
};

export default AppLayout;
