import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_fr from "./config/translations/fr/translation.json";
import common_en from "./config/translations/en/translation.json";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Reset from "./pages/ResetPassword";
import Check from "./pages/CheckMail";
import SignUp from "./pages/SignUp";
import Verification from "./pages/AccountVerification";
import YourAccount from "./components/YourAccount";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import Sidebar from "./components/SidebarHome";
import "@aws-amplify/ui-react/styles.css";
import useStore from "./hooks/Store";

// new router
import AppLayout from "./components/layout/AppLayout";
Amplify.configure(awsExports);

function App() {
  const { currentLanguage } = useStore();

  const locale = currentLanguage ?? 'en';

  i18next.init({
    interpolation: { escapeValue: false },
    lng: locale,
    resources: {
      en: {
        common: common_en,
      },
      fr: {
        common: common_fr,
      },
    },
  });

  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<AppLayout />}>
            <Route index element={<Sidebar />} />
            <Route path="/home/account-information" element={<YourAccount />} />
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/check-your-email" element={<Check />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
