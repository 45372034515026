import create from "zustand";

interface LoginState {
  mail: string;
  setMail: (mail: string) => void;

  checkmail: string;
  setCheckmail: (checkmail: string) => void;

  // user: string;
  // setUser: (mail: string) => void;

  first_name: string;
  setFirst_Name :(first_name:string) => void;



  name:string;
  setName:(name:string)=> void;

  paymentData:[];
  setPaymentData:(paymentData:[]) => void;

  lastName:string;
  setLastName:(lastName:string) => void;

  currentLanguage:string;
  setCurrentLanguage:(currentLanguage:string)=> void;

  cardExpiredFlag:boolean;
  setCardExpiredFlag:(CardExpiredFlag:boolean)=> void;

  padCardResponseData:any;
  setPadCardResponseData:(padCardResponseData:any)=> void;

  ARFEnvironment: string;
  setARFEnvironment: (ARFEnvironment:string) => void;

}

const useStore = create<LoginState>((set) => ({
  mail: "",
  setMail: (mail) =>
    set((state) => ({
      ...state,
      mail,
    })),

  checkmail: "",
  setCheckmail: (checkmail) =>
    set((state) => ({
      ...state,
      checkmail,
    })),

  // user: "",
  // setUser: (user) =>
  //   set((state) => ({
  //     ...state,
  //     user,
  //   })),

  first_name: "",
  setFirst_Name: (first_name) =>
    set((state) => ({
      ...state,
      first_name,
    })),




    lastName :"",
    setLastName:(lastName)=>{
      set((state)=>({
        ...state,
        lastName,
      }))
    },

      name:"",
      setName:(name)=>
      set((state)=>({
        ...state,
        name,
      })),


      currentLanguage:"en",
      setCurrentLanguage:(currentLanguage)=>
      set((state)=>({
        ...state,
        currentLanguage,
      })),
    

      
      paymentData :[],
      setPaymentData:(paymentData)=>{
        set((state)=>({
          ...state,
          paymentData,
        }))
      },

      cardExpiredFlag:false,
      setCardExpiredFlag:(CardExpiredFlag)=> {
        set((state)=>({
          ...state,
          CardExpiredFlag,
        }))
      },

  padCardResponseData:[],
  setPadCardResponseData:(padCardResponseData)=> { 
    set((state)=>({
      ...state,
      padCardResponseData,
    }))
  },

  ARFEnvironment: 'staging',
  setARFEnvironment: (ARFEnvironment) => {
    set((state)=>({
      ...state,
      ARFEnvironment,
    }))
  },

}));

export default useStore;