import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Auth, Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import useStore from "../hooks/Store";
import ServiceConfig from "../config/service-config.json";
import axios from "axios";
Amplify.configure(awsExports);

function YourAccount({ user }: any) {
  const [language, setLanguage] = useState<any>("");
  const { setCurrentLanguage, currentLanguage } = useStore();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  useEffect(() => {
    handleGetLanguage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentLng = localStorage.getItem("language");
    if (currentLng !== null) {
      handleAddLanguage();
    }
    // eslint-disable-next-line
  }, []);
  const firstName = user.signInUserSession.idToken.payload.name;
  const lastName = user.signInUserSession.idToken.payload["custom:last_name"];
  const userName = firstName + " " + (lastName !== undefined ? lastName : "");

  const handleChangePassword = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const logout = await Auth.signOut();
      localStorage.removeItem("language");
      navigate("/forgot-password");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  //GET_PAYMENT_METHOD
  const handleGetLanguage = () => {
    const data = {
      // email_id: user.attributes.email,
      email_id: user.signInUserSession.idToken.payload.email,
    };

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_LAMBDA_URL}/get-language`, data, headerObj)
      .then((resp) => {
        console.log("DYNAMO RESPONSE DATA", resp);
        const languageObj = resp.data.data.Item.language;
        setCurrentLanguage(languageObj);
      })
      .catch((err) => {
        console.log("DYNAMO POST ERROR", err);
      });
  };

  //Updating Language
  const updateLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const languageVal = e.target.value;
    const dataObj = ServiceConfig.language.find(
      (obj) => obj.language === languageVal
    );

    let languageObj = dataObj?.languageVal;

    setLanguage(languageObj);
  };

  const handleAddLanguage = () => {
    setCurrentLanguage(language);
    const currentLng = localStorage.getItem("language");
    const data = {
      email_id: user.signInUserSession.idToken.payload.email,
      language: currentLng !== null ? currentLng : language,
    };

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_LAMBDA_URL}/add-language`, data, headerObj)
      .then((resp) => {
        if (currentLng !== null) {
          localStorage.removeItem("language");
        }
      })
      .catch((err) => {
        console.log("DYNAMO POST ERROR", err);
      });
  };

  return (
    <div>
      <Container>
        <Card className="bp-account-body-card">
          <Card.Body className="bp-body-card">
            <Row className="d-flex">
              <Col className="bp-appointment-font2">
                <span>{t("account.yourAccount")}</span>
              </Col>
              <Row className="bp-account-table-row">
                <Col xl={3} lg={3} sm={12} xs={12}>
                  <Col className="bp-payment-name">{t("account.name")}</Col>
                  <Col className="bp-common-names">{userName}</Col>
                </Col>
                <Col xl={3} lg={3} sm={12} xs={12}>
                  <Col className="bp-payment-name">{t("account.email")}</Col>
                  <Col className="bp-common-names">
                    {user.signInUserSession.idToken.payload.email}
                  </Col>
                </Col>
                <Col xl={3} lg={3} sm={12} xs={12}>
                  <Col className="bp-payment-name">{t("account.password")}</Col>
                  <Col className="bp-common-names bp-common-password">
                    <span onClick={handleChangePassword}>
                      {t("account.changePassword")}
                    </span>
                  </Col>
                </Col>

                <Col xl={3} lg={3} sm={12} xs={12}>
                  <Col className="bp-payment-name">{t("account.language")}</Col>
                  <Col className="bp-language-btn bp-common-password">
                    <select
                      id="bp-select-form"
                      className="bp-select-language bp-select__field"
                      onChange={updateLanguage}
                    >
                      <option disabled selected>
                        {currentLanguage === "en"
                          ? t("account.english")
                          : t("account.french")}
                      </option>
                      {ServiceConfig.language.map((obj, idx) => {
                        return (
                          <option key={idx} value={obj.language}>
                            {t(obj.language)}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col className="  bp-save-btn">
                    <button onClick={handleAddLanguage} className="bp-lng-btn">
                      {t("account.apply")}
                    </button>
                  </Col>
                </Col>
              </Row>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default withAuthenticator(YourAccount);
