import { SidebarData } from "./SidebarData";
import { useNavigate } from "react-router-dom";

import "../../assets/css/bp-style.css";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleClick = (val) => {
    navigate(val.link);
  };
  return (
    <div className="bp-sideBar">
      <ul className="bp-sidebarList">
        {SidebarData.map((val, idx) => {
          return (
            <div className="bp-side-main">

                <li
                  key={idx}
                  onClick={() => {
                    handleClick(val);
                  }}
                  className="bp-rows"
                >
                  <div id="bp-side-icon">{val.icon}</div>
                  <div id="bp-side-title"> {t(val.title)}</div>
                </li>

            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;

// import { useState } from "react";
// import { ImHome3 } from "react-icons/im";
// import { MdOutlineManageAccounts } from "react-icons/md";
// import { NavLink } from "react-router-dom";
// import { FaBars } from "react-icons/fa";
// const Sidebar = ({ children }:any) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
//   const menuItem = [
//     {
//       title: "Home",
//       icon: <ImHome3 className="bp-sidebar-icon" />,
//       link: "/home",
//     },
//     {
//       title: "Account",
//       icon: <MdOutlineManageAccounts className="bp-sidebar-icon" />,
//       link: "/home/account-information",
//     },
//   ];

//   return (
//     <div className="container d-flex">
//       <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
//         <div className="top_section">
//           <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
//             Logo
//           </h1>
//           <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
//             <FaBars onClick={toggle} />
//           </div>
//         </div>
//         {menuItem.map((val, idx) => (
//           <NavLink
//             to={val.link}
//             key={idx}
//             className="link"
//             activeclassName="active"
//           >
//             <div className="icon">{val.icon}</div>
//             <div
//               style={{ display: isOpen ? "block" : "none" }}
//               className="link_text"
//             >
//               {val.title}
//             </div>
//           </NavLink>
//         ))}
//       </div>
//       <main>{children}</main>
//     </div>
//   );
// };

// export default Sidebar;
