import { useState, useEffect } from "react";
import "../assets/css/bp-style.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Amplify, Auth, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useStore from "../hooks/Store";
import GoogleIcon from "../assets/images/google-icon.png";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import awsConfig from "../aws-exports";

Amplify.configure(awsConfig);

const isLocalhost = Boolean(
  window.location.hostname === `${process.env.REACT_APP_HOST_NAME}` ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauthSignin.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauthSignin.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauthSignin,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

const Login = () => {
  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage ? storedLanguage : "en";

  const [emailAddress, setEmailAddress] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState(null as any);
  const [customState, setCustomState] = useState(null as any);
  const [isChecked, setIsChecked] = useState<boolean>(initialLanguage === "fr");
  const [isLanguage, setIsLanguage] = useState<string>(initialLanguage);
  const { setCurrentLanguage } = useStore();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
          break;
        default:
          console.log(`Unexpected event: ${event}`);
      }
    });
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
      })
      .catch((error) => console.log("Not signed in", error));

    return unsubscribe;
  }, []);

  useEffect(() => {
    const currentLabel = isChecked ? "FR" : "EN";
    const lng = currentLabel === "FR" ? "fr" : "en";
    setIsLanguage(isChecked ? "FR" : "EN");
    setCurrentLanguage(lng);
    localStorage.setItem("language", lng);
    // eslint-disable-next-line
  }, [isChecked]);

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    handlePromiseLogin();
  };

  const handlePromiseLogin = async () => {
    const promiseLogin = Auth.signIn(emailAddress, currentPassword);

    toast.promise(promiseLogin, {
      pending: t("login.pending"),
      success: t("login.welcome"),
    });
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await promiseLogin;
      setTimeout(() => {
        navigate("/home");
        handleGetLanguage();
      }, 2000);
    } catch (error) {
      console.log("error", error);
      const err = "NotAuthorizedException"
        ? t("login.error") + " " + t("login.errorMessage")
        : t("login.error");
      toast.promise(promiseLogin, {
        pending: t("login.pending"),
        error: err,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const user = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
      handleGetLanguage();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  //GETTING LANGUAGE VAL FROM DYNAMO DB
  const handleGetLanguage = () => {
    if (!user || !user.signInUserSession) {
      console.error("User or signInUserSession is null or undefined");
      return;
    }

    const data = {
      // email_id: emailAddress,
      email_id: user.signInUserSession.idToken.payload.email,
    };

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_LAMBDA_URL}/get-language`, data, headerObj)
      .then((resp) => {
        const dataObj = resp.data.data.Item.language;
        setCurrentLanguage(dataObj);
      })
      .catch((err) => {
        console.log("DYNAMO POST ERROR", err);
      });
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  if (customState) {
    console.log("customState", customState);
  }
  const handleToggleChange = () => setIsChecked(!isChecked);
  return (
    <Container>
      <Row className="bp-body">
        <Container>
          <Row>
            <Col className="bp-Auth-form-container">
              <Form
                className="bp-Auth-form"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Col className="bp-Auth-form-content">
                  <Col className="row justify-content-center bp-lg-wrapper">
                    <img
                      className="bp-login-logo"
                      src="https://www.blakepsychology.com/wp-content/uploads/2021/05/logo-english.svg"
                      alt=""
                    />
                  </Col>

                  <Col className="bp-login-title">
                    <span className="bp-sub-titile">
                      {t("login.clientPortal")}
                    </span>
                  </Col>
     
                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label style={{ marginBottom: "0" }}>
                        {t("login.email")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input "
                        type="email"
                        defaultValue={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label style={{ marginBottom: "0" }}>
                        {t("login.password")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input "
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <button className="bp-login-btn" type="submit">
                      {t("login.login")}
                    </button>
                  </Col>

                  <Col>
                    <button
                      className="bp-fed-btn"
                      onClick={handleGoogleSignIn}
                      type="button"
                    >
                      <img
                        src={GoogleIcon}
                        alt="google-icon"
                        className="bp-fed-googleImg"
                      />
                      <span className="bp-fed-span">
                        {t("account.googlSignin")}
                      </span>
                    </button>
                  </Col>
                  <Col className="mt-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={isLanguage}
                      onChange={handleToggleChange}
                      checked={isChecked}
                    />
                  </Col>
                  <Col className="bp-login-txt">
                    <span onClick={handleSignUp}>
                      {t("login.clicktoSignUp")}
                    </span>
                    <span onClick={forgotPassword}>
                      {t("login.forgotPassword")}
                    </span>
                  </Col>
                  <ToastContainer />
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Login;
