import Pyament from "./PaymentMethod";
import Account from "./YourAccount";
// import Header from './header/Example'
const SidebarHome = () => {
  return (
    <div>
      {/* <Header /> */}
        <Pyament />
        <div className="bp-sidebarHome">
          <Account />
        </div>
    </div>
  );
};

export default SidebarHome;
