import React, { useState } from "react";
import "../assets/css/bp-style.css";
import { Row, Col, Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import useStore from "../hooks/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [verificationCode, setVerificationCode] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { mail } = useStore();
  const handleVerification = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setVerificationCode(trimmedValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("mail", mail);
    Auth.confirmSignUp(mail, verificationCode)
      .then((data) => {
        console.log(data, "VERIFICATION SUCCESS");
        toast.success<unknown>(t("verification.signUp")); //t("verification.accountCreated")

        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((err) => {
        console.log(err, "ERROR!");

        if (err.name === "NotAuthorizedException") {
          toast.error<unknown>(t("signUp.errorMessage"));
        } else {
          toast.error<unknown>(t("verification.error"));
        }
      });
  };

  const verification = () => {
    navigate("/");
  };
  return (
    <Container>
      <Row className="bp-body">
        <Container>
          <Row>
            <Col className="bp-Auth-form-container">
              <Form
                className="bp-verification-Auth-form"
                onSubmit={handleSubmit}
              >
                <Col className="bp-Auth-form-content">
                  <Col className="row justify-content-center bp-lg-wrapper">
                    <img
                      className="bp-login-logo"
                      src="https://www.blakepsychology.com/wp-content/uploads/2021/05/logo-english.svg"
                      alt=""
                    />
                  </Col>

                  <Col className="bp-login-title">
                    <span className="bp-sub-titile">
                      {t("login.clientPortal")}
                    </span>
                  </Col>
                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("verification.code")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="text"
                        value={verificationCode}
                        onChange={handleVerification}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col>
                    <button className="bp-login-btn">
                      {t("verification.signUp")}
                    </button>
                  </Col>
                  <Col className="bp-login-txt" onClick={verification}>
                    <span>{t("forgot.backtoLogin")}</span>
                  </Col>
                  <ToastContainer />
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default Login;
