import React from "react";
import { Col, Row, Modal, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import CreditIcon from "../../assets/images/icon_credit.png";
import PadIcon from "../../assets/images/icon_pad.png";
import useStore from "../../hooks/Store";
interface ModalProps {
  modalShow: any;
  creditCard:any;
  padCard:any;
  setCreditCard:any;
  setPadCard:any;
  handleSetPayment:()=> void;
}

const PaymentSelectModal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslation("common");

  const {currentLanguage} = useStore();
  return (
    <div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      
        show={props.modalShow}
      >
        <Modal.Body>
          <Row style={{ paddingTop: "30px" }}>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="bp-pad-modal-card"
            >
              <p>
                <Card
                  onClick={() => {
                    props.setCreditCard(true);
                    props.setPadCard(false);
                  }}
                  className={
                    props.creditCard === true
                      ? "bp-setup-payment-card-true"
                      : "bp-setup-payment-card"
                  }
                >
                  <Col className="d-flex justify-content-center align-items-center">
                    <Card.Img
                      variant="center"
                      src={CreditIcon}
                      alt="Card image"
                      className="bp-setup-payment-card-icon"
                    />
                  </Col>
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      {t("account.creditCard")}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </p>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="bp-pad-modal-card2"
            >
              <p>
                <Card
                  onClick={() => {
                    props.setPadCard(true);
                    props.setCreditCard(false);
                  }}
                  className={
                    props.padCard === true
                      ? "bp-setup-payment-card-true"
                      : "bp-setup-payment-card"
                  }
                >
                  <Col className="d-flex justify-content-center align-items-center mt-4">
                    <Card.Img
                      src={PadIcon}
                      alt="Card image"
                      className="bp-setup-payment-card-icon"
                    />
                  </Col>
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      {t("account.pad")}
                    </Card.Title>
                    <Card.Text
                      className={currentLanguage === "en" ?"bp-pad-charity-card":"bp-pad-charity-card-french"}
                  
                    >
                      {t("account.charity")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </p>
            </Col>
          </Row>
          <Row>
          <Row>
              <Col className="bp-modal-note">
            {t('account.mandatoryModalNote')}
            </Col>
            </Row>
            <Col
              className="d-flex justify-content-sm-around bp-pad-modal-btn"
              style={{ paddingBottom: "20px" }}
            >
              <button
                onClick={props.handleSetPayment}
                className="bp-setup-payment-btn"
              >
                {t('account.paymentSetup')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentSelectModal;
