import React from "react";
import { Col, Row, Modal, Form, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

interface ModalProps {
  showBankDetails: any;
  showUpdate: boolean;
  handleCloseUpdate: () => void;
}

const PadShowDetailsModal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslation("common");

  let bankDetails: any = [];
  if (props.showBankDetails.length > 0) {
    bankDetails = props.showBankDetails.map((obj: any, idx: any) => {
      return (
        <Form noValidate>
          <Row>
            <Col
              sx={12}
              sm={12}
              lg={12}
              className="d-flex justify-content-center"
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="bp-login-label">
                  {t("account.accountNumber")}
                </Form.Label>
                <Form.Control
                  className="bp-login-input"
                  type="text"
                  value={"**** ****" + +obj.acss_debit.last4}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
            </Col>

            <Col sx={12} sm={12} lg={12} className="d-flex justify-content-center">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="bp-login-label">
                  {t("account.institutionNumber")}
                </Form.Label>
                <Form.Control
                  className="bp-login-input"
                  type="text"
                  value={obj.acss_debit.institution_number}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
            </Col>

            <Col sx={12} sm={12} lg={12} className="d-flex justify-content-center" style={{marginBottom: "10px"}}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="bp-login-label">
                  {t("account.transitNumber")}
                </Form.Label>
                <Form.Control
                  className="bp-login-input"
                  type="text"
                  value={obj.acss_debit.transit_number}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      );
    });
  }
  return (
    <div>
      <Modal show={props.showUpdate} onHide={props.handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Container>
              <Row className="d-flex justify-content-center">
                <Container className="bp-Form-wrapper">
                  {bankDetails}
                  {/* <Form noValidate >
                    <Row>
                      <Col sx={12} sm={12} lg={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="bp-login-label">
                            {t("account.accountNumber")}
                          </Form.Label>
                          <Form.Control
                            className="bp-login-input"
                            type="text"
                            value={ }
                          />
                          <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                      </Col>
                      <Col sx={12} sm={12} lg={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="bp-login-label">
                            {t("account.institutionNumber")}
                          </Form.Label>
                          <Form.Control
                            className="bp-login-input"
                            type="text"
                            // value={props.showBankDetails.acss_debit.transit_number}
                          />
                          <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                      </Col>

                      <Col sx={12} sm={12} lg={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="bp-login-label">
                            {t("account.transitNumber")}
                          </Form.Label>
                          <Form.Control
                            className="bp-login-input"
                            type="text"
                            // value={props.showBankDetails.acss_debit.transit_number}
                          />
                          <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                      </Col>
                      <Col sx={12} sm={12} lg={12}>
                        <button>close</button>
                      </Col>
                    </Row>
                  </Form> */}
                </Container>
              </Row>
            </Container>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PadShowDetailsModal;

