import { useState } from "react";
import {
  Row,
  Col,
  Container,
  Navbar,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import CloseBtn from "../../assets/images/x-btn.png";
import Blake from "../../assets/images/blake.png";
const Header = ({ user }: any) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const { t } = useTranslation("common");
  const navigate = useNavigate();

  async function signOut() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const logout = await Auth.signOut();
      localStorage.removeItem("language");
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const handleHome = () => {
    navigate("/home");
  };
  const handleAccount = () => {
    navigate("/home/account-information");
  };

  const firstName = user.signInUserSession.idToken.payload.name;
  const lastName = user.signInUserSession.idToken.payload["custom:last_name"];
  const userName = firstName + " " + (lastName !== undefined ? lastName : "");

  return (
    <div className="bp-header-container">
      <div className="bp-header-icon-body">
        <GiHamburgerMenu
          onClick={handleShowSidebar}
          className="bp-header-icon"
        />

        <Offcanvas
          show={showSidebar}
          onClick={handleCloseSidebar}
          className="bp-offcanvas"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <Row className="bp-offcanvas-logo">
                <Col>
                  <CgProfile className="bp-header-avatar " />
                </Col>
                <Col className="bp-header-name">
                  {" "}
                  {user.signInUserSession.idToken.payload.name}
                </Col>
              </Row>
            </Offcanvas.Title>
            <img src={CloseBtn} alt="" className="bp-header-close-bt" />
          </Offcanvas.Header>

          <Offcanvas.Body>
            <Row>
              <Row className="bp-header-route" onClick={handleHome}>
                <Col className="bp-header-icon-name"> {t("header.home")}</Col>
              </Row>

              <Row className="bp-header-routes" onClick={handleAccount}>
                <Col className="bp-header-icon-name">{t("header.account")}</Col>
              </Row>
              <Row className="bp-header-routes" onClick={signOut}>
                <Col className="bp-header-icon-name">{t("header.logout")}</Col>
              </Row>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Container>
        <Row className="bp-payment-body-card-example bp-body-card-example">
          <Navbar className="bp-nav">
            <Navbar.Brand href="/home">
              <div className="d-flex bp-header-logo-body">
                <img
                  src={Blake}
                  alt="John Doe"
                  className="me-1 bp-rounded-circle"
                />
                <div>
                  <span className="bp-sidebarFont">{t("blake.blake")}</span>
                  <p className="bp-client-portal-heading">
                    {t("blake.clientPortal")}
                  </p>
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Text className="bp-navbar-collapse">
              <Col className="bp-avatar-icon">
                <CgProfile className="bp-avatar" />
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="bp-name">
                    {userName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={signOut}>
                      {t("header.logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Navbar.Text>
          </Navbar>
        </Row>
      </Container>
    </div>
  );
};

export default withAuthenticator(Header);
