import React, { useState } from "react";
import "../assets/css/bp-style.css";
import { Row, Col, Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import useStore from "../hooks/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const { setCheckmail } = useStore();
  const handleLogin = () => {
    navigate("/");
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    setCheckmail(email);

    try {
      const response = await Auth.forgotPassword(email);
      console.log("forgot response", response);

      let responseData = Promise.resolve(response)
        .then((resp) => {
          console.log("forgot resp", resp);
          setTimeout(() => {
            navigate("/check-your-email");
          }, 1000);
        })
        .catch((error) => {
          console.log("Promise Forgot Error", error);
        });
      toast.promise(responseData, {
        pending: t("forgot.pending"),
        success: t("forgot.verificationCode"),
        error: t("forgot.error"),
      });
    } catch (error) {
      console.log("Promise Forgot Error", error);
    }
  };
  return (
    <Container>
      <Row className="bp-body">
        <Container>
          <Row>
            <Col className="bp-Auth-form-container">
              <Form
                className="bp-verification-Auth-form"
                onSubmit={handleSubmit}
              >
                <Col className="bp-Auth-form-content">
                  <Col className="row justify-content-center bp-lg-wrapper">
                    <img
                      className="bp-login-logo"
                      src="https://www.blakepsychology.com/wp-content/uploads/2021/05/logo-english.svg"
                      alt=""
                    />
                  </Col>

                  <Col className="bp-login-title">
                    <span className="bp-sub-titile">
                      {t("login.clientPortal")}
                    </span>
                  </Col>
                  <Col className="bp-lg-title">
                    <span className="bp-title-1">
                      {t("forgot.forgotPassword")}
                    </span>
                    <span className="bp-title-2">
                      {t("forgot.helpYpuReset")}
                    </span>
                  </Col>
                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("forgot.email")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="email"
                        // placeholder="Enter email"
                        value={email}
                        onChange={handleEmail}
                        required
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col>
                    <button className="bp-login-btn" type="submit">
                      {t("forgot.resetPassword")}
                    </button>
                  </Col>
                  <Col className="bp-login-txt">
                    <span onClick={handleLogin}>{t("forgot.backtoLogin")}</span>
                  </Col>
                  <ToastContainer />
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
