import React from "react";
import "../assets/css/bp-style.css";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStore from "../hooks/Store";

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { checkmail } = useStore();

  // const handleContinue = () => {
  //   navigate("/reset-password");
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate("/reset-password");
  };

  const handleResend = () => {
    navigate("/forgot-password");
  };
  return (
    <Container>
      <Row className="bp-body">
        <Container>
          <Row>
            <Col className="bp-Auth-form-container">
              <Form
                className="bp-verification-Auth-form"
                onSubmit={handleSubmit}
              >
                <Col className="bp-Auth-form-content">
                  <Col className="row justify-content-center bp-lg-wrapper">
                    <img
                      className="bp-login-logo"
                      src="https://www.blakepsychology.com/wp-content/uploads/2021/05/logo-english.svg"
                      alt=""
                    />
                  </Col>
                  <Col className="bp-login-title">
                    <span className="bp-sub-titile">
                      {t("login.clientPortal")}
                    </span>
                  </Col>
                  <Col className="bp-lg-title">
                    <span className="bp-title-1">
                      {" "}
                      {t("checkMail.checkEmail")}
                    </span>

                    <span className="bp-title-2">
                      {t("checkMail.passwordResetLink")}
                    </span>
                    <span className="bp-title-3"> {checkmail}</span>
                  </Col>
                  <Col className="bp-Form-wrapper">
                    <div className=" bp-email">
                      <Col>
                        <button
                          type="submit"
                          className="bp-login-btn"
                          // onClick={handleContinue}
                        >
                          {t("checkMail.continue")}
                        </button>
                      </Col>

                      <Col className="bp-email-msg-wrap">
                        <span className="bp-receive-msg">
                          {t("checkMail.didNotReceive")}{" "}
                        </span>
                        <span className="bp-send-link" onClick={handleResend}>
                          {" "}
                          {t("checkMail.resend")}
                        </span>
                      </Col>
                    </div>
                  </Col>
                </Col>
                {/* </Row> */}
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default Login;
