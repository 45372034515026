import { useEffect, useState } from "react";
import "../assets/css/bp-style.css";
import { Row, Col, Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStore from "../hooks/Store";
import { Amplify, Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import GoogleIcon from "../assets/images/google-icon.png";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import awsConfig from "../aws-exports";

const SignUp = () => {
  const [userName, setUserName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [CofirmPassword, setCofirmPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState("form-control");
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

  // const [user, setUser] = useState(null as any);
  // const [customState, setCustomState] = useState(null as any);

  const { setMail } = useStore();

  const isLocalhost = Boolean(
    window.location.hostname === `${process.env.REACT_APP_HOST_NAME}` ||
      window.location.hostname === "[::1]" ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [localRedirectSignIn, productionRedirectSignIn] =
    awsConfig.oauthSignup.redirectSignIn.split(",");

  const [localRedirectSignOut, productionRedirectSignOut] =
    awsConfig.oauthSignup.redirectSignOut.split(",");

  const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauthSignup,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut,
    },
  };

  Amplify.configure(updatedAwsConfig);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (password === CofirmPassword) {
        setShowErrorMessage(false);
        setCPasswordClass("form-control is-valid");
        console.log(cPasswordClass);
      } else {
        setShowErrorMessage(true);
        setCPasswordClass("form-control is-invalid");
      }
    }
  }, [CofirmPassword, isCPasswordDirty, password, cPasswordClass]);

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const forgotPassword = () => {
    navigate("/");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    setMail(emailAddress);

    const response = Auth.signUp({
      username: emailAddress,
      password: password,
      attributes: {
        email: emailAddress,
        name: userName,
        "custom:last_name": lastName,
      },
    });

    toast.promise(response, {
      pending: t("signUp.pending"),
      success: t("signUp.verificationCode"),
    });

    response
      .then((resp) => {
        console.log("resp", resp);
        if (password === CofirmPassword) {
          setTimeout(() => {
            navigate("/verification");
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);

        if (error.name === "UsernameExistsException") {
          resendConfirmationCode(emailAddress);
        }

        if (error.name === "InvalidPasswordException") {
          console.log('"InvalidPasswordException"');

          toast.promise(response, {
            pending: t("signUp.pending"),
            error: t("signUp.passwordError"),
          });
        }

        if (error.name === "LimitExceededException") {
          toast.promise(response, {
            pending: t("signUp.pending"),
            error: t("signUp.error"),
          });
        }
      });
  };

  const resendConfirmationCode = async (username: any) => {
    try {
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
      navigate("/verification");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  };

  //fed sign up
  const handleGoogleSignUp = async () => {
    try {
      const user = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
      console.log("User:", user);
      Auth.signOut();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCofirmPassword(e.target.value);
    setIsCPasswordDirty(true);
  };

  return (
    <Container>
      <Row className="bp-body">
        <Container>
          <Row>
            <Col className="bp-Auth-form-container">
              <Form
                className="bp-Auth-form-signup"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Col className="bp-Auth-form-content">
                  <Col className="row justify-content-center bp-lg-wrapper">
                    <img
                      className="bp-login-logo"
                      src="https://www.blakepsychology.com/wp-content/uploads/2021/05/logo-english.svg"
                      alt=""
                    />
                  </Col>

                  <Col className="bp-login-title">
                    <span className="bp-sub-titile">
                      {t("login.clientPortal")}
                    </span>
                  </Col>
                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("signUp.name")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        autoFocus={true}
                        type="text"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("signUp.lastName")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("signUp.email")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("signUp.properEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("signUp.password")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("signUp.confirmPassword")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="password"
                        value={CofirmPassword}
                        onChange={handleConfirmPassword}
                      />
                      {showErrorMessage && isCPasswordDirty ? (
                        <div className="bp-confirmPassword">
                          {" "}
                          {t("signUp.passwordnotMatch")}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>

                  <Col>
                    <button className="bp-login-btn">
                      {t("signUp.signUp")}
                    </button>
                  </Col>

                  <Col>
                    <button
                      className="bp-fed-btn"
                      type="button"
                      onClick={handleGoogleSignUp}
                    >
                      {/* */}
                      <img
                        src={GoogleIcon}
                        alt="google-icon"
                        className="bp-fed-googleImg"
                      />
                      <span className="bp-fed-span">
                        {t("account.googlSignup")}
                      </span>
                    </button>
                  </Col>

                  <Col className="bp-login-txt">
                    <span onClick={forgotPassword}>{t("login.login")}</span>
                  </Col>
                </Col>
                <ToastContainer />
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default SignUp;
