import React, { useState } from "react";
import { Col, Row, Modal, Form, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";

interface ModalProps {
  isShowPad: boolean;
  validated: boolean;
  emailId: string;
  userName: string;
  handleClosePad: () => void;
  getPaymentMethod: () => void;
}

const PadModal: React.FC<ModalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [preAuthorizedDebit, setPreAuthorizedDebit] = useState({
    padAccountNumber: "",
    institutionNumber: "",
    transitNumber: "",
  });

  const [setupIntentResp, setSetupIntentResp] = useState([] as any);

  const { t } = useTranslation("common");
  const handleSetInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValidNumber = /^\d*\.?\d*$/.test(value);

    if (isValidNumber) {
      setPreAuthorizedDebit((preAuthorizedDebit) => ({
        ...preAuthorizedDebit,
        [name]: value,
      }));
    }
  };

  const handleFormSubmitPad = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const stripe: any = await loadStripe(
      `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
    );

    const userAgent = navigator.userAgent;
    const data = {
      payment_creation: true,
      payment_method_types: ["acss_debit"],
      email: props.emailId,
      name: props.userName,
      user_agent: userAgent,
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      type: "acss_debit",
      acss_debit: {
        account_number: preAuthorizedDebit.padAccountNumber,
        institution_number: preAuthorizedDebit.institutionNumber,
        transit_number: preAuthorizedDebit.transitNumber,
      },
    };

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    let client_secret_key: any = [];
    const responseData = axios.post(
      `${process.env.REACT_APP_LAMBDA_URL}/create-setup-intent`,
      data,
      headerObj
    );
    toast.promise(responseData, {
      pending: t("account.pleaseWait"),
      error: t("account.padError"),
    });
    try {
      const paymentResponse = await responseData;
      console.log("PAD_RESPONSE", paymentResponse.data);
      let response: any = [];
      response.push(paymentResponse.data.data);
      client_secret_key.push(paymentResponse.data.data);
      setSetupIntentResp(response);
      handleReset();
    } catch (error) {
      console.log("paymentResponse Error", error);
    }

    // console.log("client_secret_key", client_secret_key[0]?.client_secret);
    const clientSecretKey = client_secret_key[0]?.client_secret;

    if (client_secret_key.length > 0) {
      const { setupIntent, error } = await stripe.confirmAcssDebitSetup(
        clientSecretKey,
        {
          payment_method: {
            billing_details: {
              name: props.userName,
              email: props.emailId,
            },
          },
        }
      );
      if (error) {
        // Inform the customer that there was an error.
        console.log("confirmAcssDebitSetupERROR", error.message);
        props.getPaymentMethod();
      } else {
        console.log("setupIntent", setupIntent);
        console.log("SetupIntent ID: " + setupIntent.id);
        console.log("SetupIntent status: " + setupIntent.status);

        props.getPaymentMethod();
      }
    }
    setLoading(false);
  };

  const handleReset = () => {
    setPreAuthorizedDebit((preAuthorizedDebit) => ({
      ...preAuthorizedDebit,
      padAccountNumber: "",
      institutionNumber: "",
      transitNumber: "",
    }));
  };

  const handleConfirmVerification = async () => {
    setSetupIntentResp([]);
    props.handleClosePad();
    // props.getPaymentMethod();
  };

  return (
    <div>
      <Modal show={props.isShowPad} onHide={props.handleClosePad}>
        <Modal.Header>
          {setupIntentResp.length === 0 ? (
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={props.handleClosePad}
            ></button>
          ) : null}
        </Modal.Header>
        <Modal.Title>
          <Row>
            <Col
              className={
                setupIntentResp.length === 0
                  ? "d-flex justify-content-center "
                  : "bp-modal-title2"
              }
            >
              {setupIntentResp.length === 0 ? (
                <h4> {t("account.pad")} </h4>
              ) : (
                <h4> {t("account.accountVerificationHeading")} </h4>
              )}
            </Col>
          </Row>
        </Modal.Title>

        {setupIntentResp.length === 0 ? (
          <Modal.Body
            className={setupIntentResp.length > 0 ? "bp-padModal-Body" : ""}
          >
            <Form
              noValidate
              validated={props.validated}
              onSubmit={handleFormSubmitPad}
            >
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-sm-around"
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="bp-login-label">
                      {t("account.accountNumber")}
                    </Form.Label>
                    <Form.Control
                      className="bp-login-input"
                      type="text"
                      maxLength={16}
                      name="padAccountNumber"
                      value={preAuthorizedDebit.padAccountNumber}
                      onChange={handleSetInputChange}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-sm-around"
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="bp-login-label">
                      {t("account.institutionNumber")}
                    </Form.Label>
                    <Form.Control
                      className="bp-login-input"
                      type="text"
                      name="institutionNumber"
                      defaultValue={preAuthorizedDebit.institutionNumber}
                      onChange={handleSetInputChange}
                      required
                    />
                    <Form.Text className="text-muted"></Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {t("account.validationExpiryYear")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-sm-around"
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="bp-login-label">
                      {t("account.transitNumber")}
                    </Form.Label>
                    <Form.Control
                      className="bp-login-input"
                      type="text"
                      name="transitNumber"
                      value={preAuthorizedDebit.transitNumber}
                      onChange={handleSetInputChange}
                      required
                    />
                    <Form.Text className="text-muted"></Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {t("account.validationExpiryYear")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-sm-around bp-padModal-btn2"
                >
                  <button className="bp-login-btn" type="submit">
                    {t("account.save")}
                  </button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        ) : (
          <Row>
            <Modal.Body className="bp-padModal-title">
              <Container>
                <p>{t("account.accountVerification")}</p>
                <p>{t("account.accountVerification2")}</p>
                <p>{t("account.accountVerification3")}</p>
              </Container>
            </Modal.Body>
            <Modal.Footer
              className="d-flex justify-content-sm-around"
              style={{ borderTop: "none" }}
            >
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <button
                    className="bp-microVerification-btn"
                    onClick={handleConfirmVerification}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : t("account.close")}
                  </button>
                </Col>
              </Row>
            </Modal.Footer>
          </Row>
        )}
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default PadModal;
