/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ca-central-1",
  aws_cognito_region: "ca-central-1",
  aws_user_pools_id: "ca-central-1_Rg9RINg4S",
  aws_user_pools_web_client_id: process.env.REACT_APP_WEB_CLIENT_ID,
  oauthSignin: {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGNIN}`,
    redirectSignOut: `${process.env.REACT_APP_REDIRECT_SIGNOUT}`,
    responseType: "token",
  },
  oauthSignup: {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGNIN}`,
    redirectSignOut: `${process.env.REACT_APP_REDIRECT_SIGNOUT}`,
    responseType: "token",
  },
  aws_cognito_username_attributes: ["EMAIL"],

  aws_cognito_signup_attributes: ["NAME", "EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
