import React, { useState, useEffect, FormEvent } from "react";
import {
  Card,
  Col,
  Row,
  Dropdown,
  Container,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/bp-style.css";
import { HiDotsHorizontal } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { FcCheckmark } from "react-icons/fc";
import PadModal from "./modal/PadModal";
import useStore from "../hooks/Store";
import PadPayment from "./PadPaymentCard";
import PaymentSelectModal from "./modal/PaymentSelectModal";
import DefaultPaymentRemoval from "./modal/DefaultPaymentRemoval";
Amplify.configure(awsExports);

function PaymentMethod({ user }: any) {
  const [cardObj, setCardObj] = useState({
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    cvcNumber: "",
  });

  const [cardUpdate, setCardUpdate] = useState({
    expiryMonth: "",
    expiryYear: "",
  });

  const [isLoading, setLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState([] as any);
  const [show, setShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [validated, setValidated] = useState(false);
  const [paymentCardDetails, setPaymentCardDetails] = useState([] as any);
  const [padPaymentCardDetails, setPadPaymentCardDetails] = useState([] as any);
  const [padPendingCards, setpadPendingCards] = useState([] as any);
  const [showMessage, setShowMessage] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [creditCard, setCreditCard] = useState(false);
  const [padCard, setPadCard] = useState(false);
  const [isShowPad, setShowPad] = useState(false);
  const [defaultPaymentRemove, setDefaultPaymentRemove] = useState(false);
  const [selectedCard, setSelectedCard] = useState([] as any);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState("");

  const { setCurrentLanguage } = useStore();

  const handleClosePad = () => setShowPad(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setModalShow(true);
  const handleCloseMessage = () => setShowMessage(false);
  const handleShowMessage = (item: any) => {
    setSelectedCard(item);
    setShowMessage(true);
  };
  const handleShowUpdate = () => setShowUpdate(true);
  const handleCloseUpdate = () => setShowUpdate(false);

  const handleCloseCard = () => {
    setIsShow(false);
  };
  const handleShowCard = () => {
    setIsShow(true);
  };

  const { t } = useTranslation("common");

  useEffect(() => {
    getPaymentMethod();
    handleGetLanguage();
    // eslint-disable-next-line
  }, []);

  const userName =
    user.signInUserSession.idToken.payload.name +
    " " +
    user.signInUserSession.idToken.payload["custom:last_name"];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const isValidNumber = /^\d*\.?\d*$/.test(value);
    if (isValidNumber) {
      setCardObj((cardObj) => ({
        ...cardObj,
        [name]: value,
      }));
    }
  };

  const handleSelectMenu = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setCardObj((cardObj) => ({
      ...cardObj,
      [name]: value,
    }));
  };

  const handleChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCardUpdate((cardUpdate) => ({
      ...cardUpdate,
      [name]: value,
    }));
  };

  const defaultPaymentModalShow = () => {
    setDefaultPaymentRemove(true);
  };

  const defaultPaymentModalClose = () => {
    setDefaultPaymentRemove(false);
  };

  const handleSelectUpdateMenu = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setCardUpdate((cardUpdate) => ({
      ...cardUpdate,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      email: user.signInUserSession.idToken.payload.email,
      name: user.signInUserSession.idToken.payload.name,
      type: "card",
      card: {
        number: cardObj.cardNumber,
        exp_month: cardObj.expiryMonth,
        exp_year: cardObj.expiryYear,
        cvc: cardObj.cvcNumber,
      },
    };

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const response = axios.post(
      `${process.env.REACT_APP_LAMBDA_URL}/create-payment-method`,
      data,
      headerObj
    );
    toast.promise(response, {
      pending: t("account.creatingPyament"),
      success: t("account.paymentCreated"),
      error: t("account.paymentError"),
    });

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const paymentResponse = await response;
      handleReset();
      setTimeout(() => {
        handleClose();
      }, 1000);
      getPaymentMethod();
    } catch (error) {
      console.log("paymentResponse Error", error);
    }
  };

  const handleChangetoDefault = async (item: any) => {
    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const data = {
      change_to_default: true,
      customer: item.customer,
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      invoice_settings: {
        default_payment_method: item.id,
      },
    };

    const promiseChangetoDefault = axios.post(
      `${process.env.REACT_APP_LAMBDA_URL}/update-payment`,
      data,
      headerObj
    );

    let responseData = Promise.resolve(promiseChangetoDefault)
      .then((resp) => {
        console.log("defaultPayment", resp);
        getPaymentMethod();
      })
      .catch((error) => {
        console.log("deafault payment error");
      });

    toast.promise(responseData, {
      pending: t("account.pleaseWait"),
      success: t("account.defaultPayment"),
      error: t("account.defaultError"),
    });
  };

  //get payment method
  const getPaymentMethod = () => {
    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const data = {
      // email: user.attributes.email,
      email: user.signInUserSession.idToken.payload.email,
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
    };

    axios
      .post(
        `${process.env.REACT_APP_LAMBDA_URL}/get-payment-methods`,
        data,
        headerObj
      )
      .then((resp: any) => {
        const responseData = resp.data.data.data;
        setPaymentCardDetails(responseData);
        getPadPayment(responseData);
        handleRetriveCustomer(responseData);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err, "ERROR");
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message === "noPayment"
        ) {
          setModalShow(true);
        }
      });
  };

  //removed payment
  const handleRemove = async () => {
    if (selectedCard.id === defaultPaymentMethodId) {
      handleCloseMessage();
      defaultPaymentModalShow();
    }

    if (selectedCard.id !== defaultPaymentMethodId) {
      const data = {
        payment_type: "creditPayment",
        paymentId: selectedCard.id,
        build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      };
      const headerObj = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };

      const promiseRemoved = axios.post(
        `${process.env.REACT_APP_LAMBDA_URL}/detach-payment`,
        data,
        headerObj
      );

      toast.promise(promiseRemoved, {
        pending: t("account.pending"),
        success: t("account.cardRemoved"),
        error: t("account.cardNotRemoved"),
      });

      try {
        const response = await promiseRemoved;
        console.log("response", response);
        getPaymentMethod();
        setTimeout(() => {
          handleCloseMessage();
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  //update payment
  const handleSubmitUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const data = {
      update_card_id: cardDetails.id,
      update_payment: true,
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      card: {
        exp_month: cardUpdate.expiryMonth,
        exp_year: cardUpdate.expiryYear,
      },
    };

    const promiseUpdate = axios.post(
      `${process.env.REACT_APP_LAMBDA_URL}/update-payment`,
      data,
      headerObj
    );

    toast.promise(promiseUpdate, {
      pending: t("account.updatingCard"),
      success: t("account.cardUpdated"),
      error: t("account.cardNotUpdated"),
    });

    try {
      const response = await promiseUpdate;
      console.log("update response", response);
      getPaymentMethod();
      setTimeout(() => {
        handleCloseUpdate();
      }, 1000);
      handleResetCard();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleResetCard = () => {
    setCardUpdate((cardUpdate) => ({
      ...cardUpdate,
      expiryMonth: "",
      expiryYear: "",
    }));
  };

  //GET_PAYMENT_METHOD
  const handleGetLanguage = () => {
    const data = {
      // email_id: user.attributes.email,
      email_id: user.signInUserSession.idToken.payload.email,
    };

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_LAMBDA_URL}/get-language`, data, headerObj)
      .then((resp) => {
        const languageObj = resp.data.data.Item.language;
        setCurrentLanguage(languageObj);
      })
      .catch((err) => {
        console.log("DYNAMO POST ERROR", err);
      });
  };

  //CHECK THE CARD EXPIRED OR NOT
  const hasCardExpired = (validToMonth: string, validToYear: string) => {
    let hasCardExpiredFlag = false;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    if (currentYear > parseInt(validToYear, 10)) {
      hasCardExpiredFlag = true;
    } else if (
      currentMonth > parseInt(validToMonth, 10) &&
      currentYear === parseInt(validToYear, 10)
    ) {
      hasCardExpiredFlag = true;
    }
    return hasCardExpiredFlag;
  };

  const handleReset = () => {
    setCardObj((cardObj) => ({
      ...cardObj,
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvcNumber: "",
    }));
  };

  const handleRetriveCustomer = (item: any) => {
    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    if (item.length > 0) {
      const data = {
        customerId: item[0].customer,
        build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      };
      axios
        .post(
          `${process.env.REACT_APP_LAMBDA_URL}/retrive-customer`,
          data,
          headerObj
        )
        .then((resp) => {
          const defaultPaymentId =
            resp.data.data.invoice_settings.default_payment_method;
          setDefaultPaymentMethodId(defaultPaymentId);
        })
        .catch((err) => {
          console.log("DYNAMO POST ERROR", err);
        });
    }
  };

  //modal for card display
  let paymentDetails = [] as any;
  if (paymentCardDetails.length > 0) {
    paymentDetails =
      paymentCardDetails &&
      paymentCardDetails.map((item: any, index: any) => {
        return (
          <Col
            xl={6}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className=" bp-card-row-account "
            key={index}
          >
            <Card className="bp-payment-method-card">
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Dropdown className="d-flex justify-content-end mt-1">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="bp-dot-btn "
                      >
                        <Col>
                          <HiDotsHorizontal className="bp-dot-icon2" />
                        </Col>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bp-payment-menu">
                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleShowMessage(item);
                            setSelectedCard(item);
                          }}
                        >
                          {t("account.removeCard")}
                        </Dropdown.Item>
                        <Modal show={showMessage} onHide={handleCloseMessage}>
                          <Row>
                            <Col className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleCloseMessage}
                              ></button>
                            </Col>
                          </Row>
                          <Modal.Header className="d-flex justify-content-around">
                            <h5>{t("account.confirm")}?</h5>
                          </Modal.Header>
                          <Modal.Body className="d-flex justify-content-around">
                            {t("account.deleteMessage")}
                          </Modal.Body>
                          <Modal.Footer className="bp-modal-footer">
                            <Row className="bp-message-btn">
                              <Col>
                                <button
                                  onClick={() => {
                                    handleRemove();
                                  }}
                                  className="bp-yes-btn"
                                >
                                  {t("account.yes")}
                                </button>
                              </Col>
                              <Col>
                                <button
                                  onClick={handleCloseMessage}
                                  className="bp-no-btn"
                                >
                                  {t("account.no")}
                                </button>
                              </Col>
                            </Row>
                          </Modal.Footer>
                          <ToastContainer />
                        </Modal>

                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleShowUpdate();
                            setCardDetails(item);
                          }}
                        >
                          {t("account.updateCard")}
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            setCardDetails(item);
                            handleShowCard();
                          }}
                        >
                          {t("account.showCard")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleChangetoDefault(item);
                          }}
                        >
                          {t("account.changetoDefault")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Row>

                  <Row xs="auto" className="bp-card-val">
                    <Col>
                      {" "}
                      <img
                        src={`/images/${item.card.brand}.png`}
                        alt=""
                        className="bp-card-img"
                      />
                    </Col>
                    <Col className="bp-card-number ">
                      {"**** **** ****  " + item.card.last4}
                    </Col>
                    <Col className="bp-card-year">
                      {" "}
                      {item.card.exp_month + "/" + item.card.exp_year}
                    </Col>
                  </Row>

                  <Row className="bp-card-val2">
                    <Col sm={12} md={4} lg={4} xl={4}>
                      <img
                        src={`/images/${item.card.brand}.png`}
                        alt=""
                        className="bp-card-img"
                      />
                    </Col>
                    <Col className="bp-card-number ">
                      {"**** **** ****  " + item.card.last4}
                    </Col>
                    <Col className="bp-card-year">
                      {" "}
                      {item.card.exp_month + "/" + item.card.exp_year}
                    </Col>
                  </Row>

                  {hasCardExpired(item.card.exp_month, item.card.exp_year) ? (
                    <Col>
                      <button className="bp-expired-btn">
                        <Col>
                          <span className="bp-expired-font">
                            {t("account.expired")}
                          </span>
                        </Col>
                      </button>
                    </Col>
                  ) : item.id === defaultPaymentMethodId ? (
                    <Col>
                      <button className="bp-default-btn">
                        <Col className="bp-tick-icon">
                          <FcCheckmark />
                          <span className="bp-default-font">
                            {t("account.defaultPayment")}
                          </span>
                        </Col>
                      </button>
                    </Col>
                  ) : null}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      });
  }

  //GET-PAD-PAYMENT
  const getPadPayment = (responseData: any) => {
    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const data = {
      // email: user.attributes.email,
      email: user.signInUserSession.idToken.payload.email,
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
    };

    axios
      .post(
        `${process.env.REACT_APP_LAMBDA_URL}/get-pad-payment`,
        data,
        headerObj
      )
      .then((resp: any) => {
        const response = resp.data.data.data;
        const response2 = resp.data.data2;
        setPadPaymentCardDetails(response);
        setpadPendingCards(response2);
        handleMandatoryModal(responseData, response);
        defaultPaymentUpdate(responseData, response);
      })
      .catch((err) => {
        console.log("PAD PROMISE ERROR", err);
      });
  };

  const defaultPaymentUpdate = (responseData: any, response: any) => {
    //check if resp1 and response0 anel resp else response first element
    let customerId = "";
    let paymentId = "";
    if (response.length === 1 && responseData.length === 0) {
      customerId = response?.[0].customer;
      paymentId = response?.[0].id;
    } else if (response.length === 0 && responseData.length === 1) {
      customerId = responseData?.[0].customer;
      paymentId = responseData?.[0].id;
    }

    if (customerId) {
      const headerObj = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };

      const data = {
        change_to_default: true,
        customer: customerId,
        build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
        invoice_settings: {
          default_payment_method: paymentId,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_LAMBDA_URL}/update-payment`,
          data,
          headerObj
        )
        .then((response) => {
          const defaultPaymentId =
            response.data.data.invoice_settings.default_payment_method;
          setDefaultPaymentMethodId(defaultPaymentId);
        })
        .catch((error) => {
          console.log("autoupdate payment error", error);
        });
    }
  };

  const handleMandatoryModal = (responseData: any, response: any) => {
    let dataObj: any = [];
    //CHECKING THE CARD IS EXPIRED OR NOT
    responseData.forEach((item: any) => {
      const validToMonth = item.card.exp_month;
      const validToYear = item.card.exp_year;

      let hasCardExpiredFlag = false;
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      if (currentYear > parseInt(validToYear, 10)) {
        hasCardExpiredFlag = true;
      } else if (
        currentMonth > parseInt(validToMonth, 10) &&
        currentYear === parseInt(validToYear, 10)
      ) {
        hasCardExpiredFlag = true;
      }

      dataObj.push(hasCardExpiredFlag);
    });

    const cardBooleanArray = dataObj.map((obj: any) => obj);
    const cardResult = cardBooleanArray.every((value: any) => value === true);

    if (
      (responseData.length === 0 || cardResult === true) &&
      response.length === 0
    ) {
      setModalShow(true);
    }
  };

  const handleSetPayment = () => {
    // setModalShow(false);
    if (creditCard === true) {
      setShow(true);
      setModalShow(false);
    }

    if (padCard === true) {
      setShowPad(true);
      setModalShow(false);
    }
  };

  return (
    <div>
      <Container>
        <Card className="bp-payment-body-card">
          <Card.Body className="bp-body-card">
            {isLoading ? (
              <Spinner animation="border" />
            ) : (
              <>
                <Row className="bp-appointment-font ">
                  <span>{t("account.paymentMethod")}</span>
                </Row>
                <Row sm="auto" className="bp-card-row-payment">
                  <Col>
                    <Row className="">{paymentDetails}</Row>
                    <Row>
                      <PadPayment
                        defaultPaymentModalShow={defaultPaymentModalShow}
                        defaultPaymentMethodId={defaultPaymentMethodId}
                        padPaymentCardDetails={padPaymentCardDetails}
                        padPendingCards={padPendingCards}
                        getPaymentMethod={getPaymentMethod}
                        userName={userName}
                        email={user.signInUserSession.idToken.payload.email}
                      />
                    </Row>
                  </Col>
                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="  bp-card-row-account  mb-4"
                  >
                    <Card className="bp-payment-add-card ">
                      <Card.Body>
                        <Card.Text>
                          <Row>
                            <Col className="bp-icons-plus" onClick={handleShow}>
                              <AiOutlinePlus className="bp-plus-icon" />
                              <span className="text-center bp-addPayment">
                                {t("account.addPayment")} <br />
                                {t("account.method")}
                              </span>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Row>
                <Col>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <h5>{t("account.creditCard")}</h5>
                      </Col>
                    </Row>
                    <Modal.Body>
                      <Row className="d-flex justify-content-center">
                        <Container>
                          <Row className="d-flex justify-content-center">
                            <Container className="bp-Form-wrapper">
                              <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                              >
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.cardNumber")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        type="text"
                                        name="cardNumber"
                                        maxLength={16}
                                        value={cardObj.cardNumber}
                                        onChange={handleChange}
                                        required
                                      />
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationCardNumber")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.expiryMonth")}
                                      </Form.Label>

                                      <Col>
                                        <select
                                          id=""
                                          className="bp-custom-select bp-my-custom-select"
                                          name="expiryMonth"
                                          value={cardObj.expiryMonth}
                                          onChange={handleSelectMenu}
                                        >
                                          <option disabled selected></option>
                                          <option>01</option>
                                          <option>02</option>
                                          <option>03</option>
                                          <option>04</option>
                                          <option>05</option>
                                          <option>06</option>
                                          <option>07</option>
                                          <option>08</option>
                                          <option>09</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                        </select>
                                      </Col>
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationExpiryMonth")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.expiryYear")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        type="text"
                                        name="expiryYear"
                                        maxLength={4}
                                        value={cardObj.expiryYear}
                                        onChange={handleChange}
                                        required
                                      />
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationExpiryYear")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.cvc")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        type="password"
                                        name="cvcNumber"
                                        value={cardObj.cvcNumber}
                                        onChange={handleChange}
                                        required
                                      />
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationCvc")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    <Col>
                                      <button
                                        className="bp-login-btn"
                                        type="submit"
                                      >
                                        {t("account.save")}
                                      </button>
                                    </Col>
                                  </Col>
                                </Row>
                                <ToastContainer />
                              </Form>
                            </Container>
                          </Row>
                        </Container>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </Col>
                <Col>
                  <Modal show={isShow} onClick={handleCloseCard}>
                    <Modal.Header closeButton>
                      <Row className="ps-1">
                        <Col className="ps-5">
                          <Modal.Title></Modal.Title>
                        </Col>
                      </Row>
                    </Modal.Header>
                    <Modal.Body>
                      <Row className="d-flex justify-content-center">
                        <Container>
                          <Row className="d-flex justify-content-center">
                            <Container className="bp-Form-wrapper">
                              <Row>
                                {cardDetails.customer ? (
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.cardBrand")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        value={cardDetails.card.brand}
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.cardType")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        value={cardDetails.card.funding}
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.cardLastDigit")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        value={
                                          "**** **** **** " +
                                          +cardDetails.card.last4
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.expiryDate")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        value={
                                          cardDetails.card.exp_month +
                                          "/" +
                                          cardDetails.card.exp_year
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                ) : null}
                              </Row>
                            </Container>
                          </Row>
                        </Container>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </Col>
                <Col>
                  <Modal show={showUpdate} onHide={handleCloseUpdate}>
                    <Modal.Header closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row className="d-flex justify-content-center">
                        <Container>
                          <Row className="d-flex justify-content-center">
                            <Container className="bp-Form-wrapper">
                              <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmitUpdate}
                              >
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationPaymentType")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    {cardDetails.customer ? (
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label className="bp-login-label">
                                          {t("account.cardNumber")}
                                        </Form.Label>
                                        <Form.Control
                                          className="bp-login-input"
                                          type="text"
                                          name="cardNumber"
                                          value={
                                            "**** **** **** " +
                                            +cardDetails.card.last4
                                          }
                                          required
                                        />
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                          {t("account.validationCardNumber")}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    ) : null}

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.expiryMonth")}
                                      </Form.Label>

                                      <Col>
                                        <select
                                          id=""
                                          className="bp-custom-select bp-my-custom-select"
                                          name="expiryMonth"
                                          defaultValue={cardUpdate.expiryMonth}
                                          onChange={handleSelectUpdateMenu}
                                        >
                                          <option disabled selected></option>
                                          <option>01</option>
                                          <option>02</option>
                                          <option>03</option>
                                          <option>04</option>
                                          <option>05</option>
                                          <option>06</option>
                                          <option>07</option>
                                          <option>08</option>
                                          <option>09</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                        </select>
                                      </Col>
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationExpiryMonth")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label className="bp-login-label">
                                        {t("account.expiryYear")}
                                      </Form.Label>
                                      <Form.Control
                                        className="bp-login-input"
                                        type="text"
                                        name="expiryYear"
                                        maxLength={4}
                                        value={cardUpdate.expiryYear}
                                        onChange={handleChangeUpdate}
                                        required
                                      />
                                      <Form.Text className="text-muted"></Form.Text>
                                      <Form.Control.Feedback type="invalid">
                                        {t("account.validationExpiryYear")}
                                      </Form.Control.Feedback>
                                    </Form.Group>

                                    <Col>
                                      <button
                                        className="bp-login-btn"
                                        type="submit"
                                      >
                                        {t("account.save")}
                                      </button>
                                    </Col>
                                  </Col>
                                </Row>
                                <ToastContainer />
                              </Form>
                            </Container>
                          </Row>
                        </Container>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </Col>
                {/* AUTOMATIC MODAL */}
                <Col>
                  <PaymentSelectModal
                    modalShow={modalShow}
                    creditCard={creditCard}
                    padCard={padCard}
                    handleSetPayment={handleSetPayment}
                    setCreditCard={setCreditCard}
                    setPadCard={setPadCard}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PadModal
                    handleClosePad={handleClosePad}
                    isShowPad={isShowPad}
                    emailId={user.signInUserSession.idToken.payload.email}
                    userName={userName}
                    validated={validated}
                    getPaymentMethod={getPaymentMethod}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DefaultPaymentRemoval
                    defaultPaymentModalShow={defaultPaymentModalShow}
                    modalShow={defaultPaymentRemove}
                    defaultPaymentModalClose={defaultPaymentModalClose}
                  />
                </Col>
              </Row>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default withAuthenticator(PaymentMethod);
