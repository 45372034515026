import { ImHome3 } from "react-icons/im";
import { MdOutlineManageAccounts } from "react-icons/md";

export const SidebarData = [
  {
    title: "header.home",
    icon: <ImHome3 className="bp-sidebar-icon"/>,
    link: "/home",
  },
  {
    title: "header.account",
    icon: <MdOutlineManageAccounts className="bp-sidebar-icon"/>,
    link: "/home/account-information",
  },
];
