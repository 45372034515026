import React, { useEffect, useState } from "react";
import { Card, Col, Row, Dropdown, Modal, Form } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowCardDetails from "./modal/PadShowDetailsModal";
import { FcCheckmark } from "react-icons/fc";
import { MdOutlinePending } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import { loadStripe } from "@stripe/stripe-js";
interface padProps {
  padPaymentCardDetails: any;
  padPendingCards: any;
  getPaymentMethod: () => void;
  defaultPaymentMethodId: any;
  defaultPaymentModalShow: () => void;
  userName: any;
  email: any;
}

const PadPaymentCard: React.FC<padProps> = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [showVerifyMessage, setShowVerifyMessage] = useState(false);
  const [showBankDetails, setShowBankDetails] = useState([] as any);
  const [showUpdate, setShowUpdate] = useState(false);
  const [paymentsId, setPaymentsId] = useState("");
  const [selectedCard, setSelectedCard] = useState([] as any);
  const [selectedPendingCard, setSelectedPendingCards] = useState([] as any);
  const [showPendingCard, setShowPendingCard] = useState(false);
  const [selectVerifyCards, setSelectVerifyCards] = useState([] as any);
  const [firstAmount, setFirstAmount] = useState("");
  const [lastAmount, setLastAmount] = useState("");
  const { t } = useTranslation("common");

  const handleCloseMessage = () => setShowMessage(false);
  const handleCloseVerifyModal = () => setShowVerifyMessage(false);

  const handleClosePendingMessage = () => setShowPendingCard(false);

  const handleShowMessage = (item: any) => {
    setSelectedCard(item);
    setShowMessage(true);
  };

  const handleShowVerifyModal = (item: any) => {
    setShowVerifyMessage(true);
    setSelectVerifyCards(item);
  };

  const handleShowRemoveModal = (item: any) => {
    setSelectedPendingCards(item);
    setShowPendingCard(true);
  };

  useEffect(() => {
    handleRetriveCustomer();
  });

  const handleRemove = async () => {
    console.log("item!!!", selectedCard.id);
    console.log("defaultPaymentMethodId", props.defaultPaymentMethodId);

    if (selectedCard.id === props.defaultPaymentMethodId) {
      handleCloseMessage();
      props.defaultPaymentModalShow();
    }

    if (selectedCard.id !== props.defaultPaymentMethodId) {
      const data = {
        payment_type: "padPayment",
        paymentId: selectedCard.id,
        build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      };
      const headerObj = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };

      const promiseRemoved = axios.post(
        `${process.env.REACT_APP_LAMBDA_URL}/detach-payment`,
        data,
        headerObj
      );

      toast.promise(promiseRemoved, {
        pending: t("account.removingPadPending"),
        success: t("account.RemovePadSucess"),
        error: t("account.removePadError"),
      });

      try {
        const response = await promiseRemoved;
        console.log("detachPyamentResponse", response);
        props.getPaymentMethod();
        setTimeout(() => {
          handleCloseMessage();
        }, 3000);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleRemovePending = () => {
    console.log("selectedPendingCard", selectedPendingCard);

    // /cancel-setup-intent

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const data = {
      id: selectedPendingCard.id,
    };
    const cancelResponse = axios.post(
      `${process.env.REACT_APP_LAMBDA_URL}/cancel-setup-intent`,
      data,
      headerObj
    );

    let response = Promise.resolve(cancelResponse)
      .then((resp: any) => {
        console.log("CancelResponse", resp);
        console.log("response", response);
        props.getPaymentMethod();
      })
      .catch((error) => {
        console.log("setupIntentCancelError", error);
      });
    console.log("responsesetupintentCancel", response);

    toast.promise(response, {
      pending: t("account.toastSetupRemoving"),
      success: t("account.toastSetupintentRemoved"),
      error: t("account.toastSetupintentNotRemoved"),
    });
  };

  const handleShowCardDetails = (item: any) => {
    const showResponse: any = [];
    showResponse.push(item);
    setShowBankDetails(showResponse);
    handleShowUpdate();
  };

  const handleShowUpdate = () => setShowUpdate(true);
  const handleCloseUpdate = () => setShowUpdate(false);

  const handleRetriveCustomer = () => {
    if (props.padPaymentCardDetails.length > 0) {
      const headerObj = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };

      const data = {
        customerId: props.padPaymentCardDetails?.[0].customer,
        build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      };
      axios
        .post(
          `${process.env.REACT_APP_LAMBDA_URL}/retrive-customer`,
          data,
          headerObj
        )
        .then((resp) => {
          const defaultPaymentId =
            resp.data.data.invoice_settings.default_payment_method;
          setPaymentsId(defaultPaymentId);
        })
        .catch((err) => {
          console.log("DYNAMO POST ERROR", err);
        });
    }
  };

  const handleMicroDeposit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log("MICRO_DEPOSIT STARTED");
    const stripe: any = await loadStripe(
      `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
    );
    const response = selectVerifyCards;
    const { microDepositVerification, error } =
      stripe.verifyMicrodepositsForSetup(response.client_secret, {
        amounts: [firstAmount, lastAmount],
      });

    if (error) {
      // Inform the customer that there was an error.
      console.log("confirmAcssDebitSetupERROR", error.message);
    } else {
      console.log("microDepositVerification", microDepositVerification);
      setFirstAmount("");
      setLastAmount("");
      props.getPaymentMethod();
      handleCloseVerifyModal();
    }

    
  };

  const handleConfirmAcssDebit = async (item: any) => {
  
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const stripe: any = await loadStripe(
            `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
          );

          const setupIntent = await stripe.confirmAcssDebitSetup(
            item.client_secret,
            {
              payment_method: {
                billing_details: {
                  name: props.userName,
                  email: props.email,
                },
              },
            }
          );
          props.getPaymentMethod();
          resolve(setupIntent);
        } catch (error) {
          props.getPaymentMethod();
          reject(error);
        }
      }),
      {
        pending: t("account.pleaseWait"),
        success: t("account.accountSuccessfullyConfirmed"),
        error: t("account.pleaseTryAgain"),
      }
    );
  };

  //changeToDefault
  const handleChangetoDefault = async (item: any) => {
    console.log("handleChangetoDefaultPAD", item);

    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const data = {
      change_to_default: true,
      customer: item.customer,
      build_environment: process.env.REACT_APP_BUILD_DEVELOPMENT,
      invoice_settings: {
        default_payment_method: item.id,
      },
    };

    const promiseChangetoDefault = axios.post(
      `${process.env.REACT_APP_LAMBDA_URL}/update-payment`,
      data,
      headerObj
    );

    let responseData = Promise.resolve(promiseChangetoDefault)
      .then((resp) => {
        console.log("responseData", resp);

        props.getPaymentMethod();
      })
      .catch((error) => {
        console.log("deafault payment error", error);
      });

    toast.promise(responseData, {
      pending: t("account.pleaseWait"),
      success: t("account.defaultPayment"),
      error: t("account.defaultError"),
    });
  };

  let paymentDetails: any = [];
  if (props.padPaymentCardDetails.length > 0) {
    paymentDetails =
      props.padPaymentCardDetails &&
      props.padPaymentCardDetails.map((item: any, index: any) => {
        return (
          <Col
            xl={6}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className=" bp-card-row-account "
            style={{
              marginBottom: "20px",
            }}
            key={index}
          >
            <Card className="bp-payment-method-card">
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Dropdown className="d-flex justify-content-end mt-1">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="bp-dot-btn "
                      >
                        <Col>
                          <HiDotsHorizontal className="bp-dot-icon2" />
                        </Col>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bp-payment-menu">
                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleShowMessage(item);
                            setSelectedCard(item);
                          }}
                        >
                          {t("account.removeCard")}
                        </Dropdown.Item>
                        <Modal show={showMessage} onHide={handleCloseMessage}>
                          <Row>
                            <Col className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleCloseMessage}
                              ></button>
                            </Col>
                          </Row>
                          <Modal.Header className="d-flex justify-content-around">
                            <h5>{t("account.confirm")}?</h5>
                          </Modal.Header>
                          <Modal.Body className="d-flex justify-content-around">
                            {t("account.deleteMessage")}
                          </Modal.Body>
                          <Modal.Footer className="bp-modal-footer">
                            <Row className="bp-message-btn">
                              <Col>
                                <button
                                  onClick={() => {
                                    handleRemove();
                                  }}
                                  className="bp-yes-btn"
                                >
                                  {t("account.yes")}
                                </button>
                              </Col>
                              <Col>
                                <button
                                  onClick={handleCloseMessage}
                                  className="bp-no-btn"
                                >
                                  {t("account.no")}
                                </button>
                              </Col>
                            </Row>
                          </Modal.Footer>
                        </Modal>

                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleShowCardDetails(item);
                          }}
                        >
                          {t("account.showCard")}
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleChangetoDefault(item);
                          }}
                        >
                          {t("account.changetoDefault")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Row>

                  <Row xs="auto" className="bp-padCard-val">
                    <Col>
                      <BsBank2 className="bp-padcard-img" />
                    </Col>
                    <Col className="bp-card-number ">
                      {" **** ****  " + item.acss_debit.last4}
                    </Col>
                    <Col className="bp-card-year">
                      {item.acss_debit.institution_number}
                    </Col>
                    <Col className="bp-card-year">
                      {item.acss_debit.transit_number}
                    </Col>
                  </Row>
                  <Row>
                    {paymentsId === item.id ? (
                      <Col>
                        <button className="bp-padDefault-btn">
                          <Col className="bp-tick-icon">
                            <FcCheckmark />
                            <span className="bp-default-font">
                              {t("account.defaultPayment")}
                            </span>
                          </Col>
                        </button>
                      </Col>
                    ) : null}
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      });
  }

  //pendingverification
  let pendingVerification = [];
  const microResp = props.padPendingCards.filter(
    (obj: any) => obj.status !== "succeeded"
  );
  if (microResp.length > 0) {
    pendingVerification =
      microResp &&
      microResp.map((item: any, index: any) => {
        return (
          <Col
            xl={6}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className=" bp-card-row-account "
            style={{
              marginBottom: "20px",
            }}
            key={index}
          >
            <Card className="bp-payment-method-card">
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Dropdown className="d-flex justify-content-end mt-1">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="bp-dot-btn "
                      >
                        <Col>
                          <HiDotsHorizontal className="bp-dot-icon2" />
                        </Col>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bp-payment-menu">
                        <Dropdown.Item
                          className="bp-options"
                          onClick={() => {
                            handleShowRemoveModal(item);
                            setSelectedCard(item);
                          }}
                        >
                          {t("account.removePadCard")}
                        </Dropdown.Item>
                        <Modal
                          show={showPendingCard}
                          onHide={handleClosePendingMessage}
                        >
                          <Row>
                            <Col className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleClosePendingMessage}
                              ></button>
                            </Col>
                          </Row>
                          <Modal.Header className="d-flex justify-content-around">
                            <h5>{t("account.confirm")}?</h5>
                          </Modal.Header>
                          <Modal.Body className="d-flex justify-content-around">
                            {t("account.deleteMessage")}
                          </Modal.Body>
                          <Modal.Footer className="bp-modal-footer">
                            <Row className="bp-message-btn">
                              <Col>
                                <button
                                  onClick={() => {
                                    handleRemovePending();
                                  }}
                                  className="bp-yes-btn"
                                >
                                  {t("account.yes")}
                                </button>
                              </Col>
                              <Col>
                                <button
                                  onClick={handleClosePendingMessage}
                                  className="bp-no-btn"
                                >
                                  {t("account.no")}
                                </button>
                              </Col>
                            </Row>
                          </Modal.Footer>
                        </Modal>

                        {item.status === "requires_action" ? (
                          <Dropdown.Item
                            className="bp-options"
                            onClick={() => {
                              handleShowVerifyModal(item);
                              setSelectVerifyCards(item);
                            }}
                          >
                            {t("account.verifyAccount")}
                          </Dropdown.Item>
                        ) : item.status === "requires_payment_method" ? (
                          <Dropdown.Item
                            className="bp-options"
                            onClick={() => {
                              handleConfirmAcssDebit(item);
                            }}
                          >
                            {t("account.verifyAccount")}
                          </Dropdown.Item>
                        ) : null}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Modal
                      show={showVerifyMessage}
                      onHide={handleCloseVerifyModal}
                    >
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleCloseVerifyModal}
                          ></button>
                        </Col>
                      </Row>
                      <Modal.Header className="d-flex justify-content-around">
                        <h5>{t("account.pleaseEnterMicroAmount")}</h5>
                      </Modal.Header>
                      <Modal.Body className="d-flex justify-content-around">
                        <Form noValidate onSubmit={handleMicroDeposit}>
                          <Row>
                            <Col
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="d-flex justify-content-sm-around"
                            >
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="bp-login-label">
                                  {t("account.mircroFirstAmount")}
                                </Form.Label>
                                <Form.Control
                                  className="bp-login-input"
                                  type="text"
                                  maxLength={16}
                                  name="padAccountNumber"
                                  value={firstAmount}
                                  onChange={(e) => {
                                    setFirstAmount(e.target.value);
                                  }}
                                  required
                                />
                              </Form.Group>
                            </Col>

                            <Col
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="d-flex justify-content-sm-around"
                            >
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="bp-login-label">
                                  {t("account.microSecondAmount")}
                                </Form.Label>
                                <Form.Control
                                  className="bp-login-input"
                                  type="text"
                                  name="institutionNumber"
                                  value={lastAmount}
                                  onChange={(e) => {
                                    setLastAmount(e.target.value);
                                  }}
                                  required
                                />
                                <Form.Text className="text-muted"></Form.Text>
                                <Form.Control.Feedback type="invalid">
                                  {t("account.validationExpiryYear")}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="bp-message-btn">
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <button
                                className="bp-yes-btn"
                                style={{ width: "347px", height: "44px" }}
                                type="submit"
                              >
                                {t("account.submit")}
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer className="bp-modal-footer"></Modal.Footer>
                    </Modal>
                  </Row>

                  <Row xs="auto" className="bp-padCard-val">
                    <Col>
                      <BsBank2 className="bp-padcard-img" />
                    </Col>
                    <Col className="bp-card-number ">{" **** ****  XXXX"}</Col>
                    <Col className="bp-card-year">{"XXX"}</Col>
                    <Col className="bp-card-year">{"XXXXX"}</Col>
                  </Row>
                  <Row>
                    {item.status === "requires_action" ? (
                      <Col>
                        <button className="bp-padPending-btn">
                          <Col className="bp-tick-icon">
                            <MdOutlinePending />
                            <span className="bp-pending-font">
                              {t("account.pendingVerification")}
                            </span>
                          </Col>
                        </button>
                      </Col>
                    ) : item.status === "requires_payment_method" ? (
                      <Col>
                        <button className="bp-padPending-btn">
                          <Col className="bp-tick-icon">
                            <MdOutlinePending />
                            <span className="bp-pending-font">
                              Requires payment method
                            </span>
                          </Col>
                        </button>
                      </Col>
                    ) : null}
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      });
  }

  return (
    <div>
      <Row>
        <Col> {paymentDetails}</Col>
        <Col>{pendingVerification}</Col>
      </Row>
      <ShowCardDetails
        showBankDetails={showBankDetails}
        showUpdate={showUpdate}
        handleCloseUpdate={handleCloseUpdate}
      />
      <ToastContainer />
    </div>
  );
};

export default PadPaymentCard;
