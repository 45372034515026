import React, { useState } from "react";
import "../assets/css/bp-style.css";
import { Row, Col, Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import useStore from "../hooks/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { checkmail } = useStore();


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Auth.forgotPasswordSubmit(checkmail, verificationCode, password)
      .then((data) => {
        console.log(data, "data");
        toast.success<unknown>(t("resetPassword.toastMessage"));

        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        console.log(err, "Error");
      });
  };

  return (
    <Container>
      <Row className="bp-body">
        <Container>
          <Row>
            <Col className="bp-Auth-form-container">
              <Form
                className="bp-verification-Auth-form"
                onSubmit={handleSubmit}
              >
                <Col className="bp-Auth-form-content">
                  <Col className="row justify-content-center bp-lg-wrapper">
                    <img
                      className="bp-login-logo"
                      src="https://www.blakepsychology.com/wp-content/uploads/2021/05/logo-english.svg"
                      alt=""
                    />
                  </Col>

                  <Col className="bp-login-title">
                    <span className="bp-sub-titile">
                      {t("login.clientPortal")}
                    </span>
                  </Col>
                  <Col className="bp-lg-title">
                    <span className="bp-title-1">
                      {t("resetPassword.resetPassword")}
                    </span>
                    <span className="bp-title-2">
                      {t("resetPassword.addNewPassword")}
                    </span>
                  </Col>
                  <Col className="form-group mt-3">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("resetPassword.code")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="text"
                        // placeholder="Enter email"
                        value={verificationCode}
                        onChange={(event) =>
                          setVerificationCode(event.target.value)
                        }
                      />
                      <Form.Text className="text-muted"></Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t("login.validationEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col className=" bp-email">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="bp-login-label">
                        {t("resetPassword.newPassword")}
                      </Form.Label>
                      <Form.Control
                        className="bp-login-input"
                        type="password"
                        // placeholder="Enter email"
                        value={password}
                        onChange={handleChange}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Col>
                      <button className="bp-login-btn">
                        {t("resetPassword.resetLogin")}
                      </button>
                    </Col>
                  </Col>
                  <ToastContainer />
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default Login;
